import { museumPage } from '@project/api/museum-page/v1'
import { historicalSite } from '@project/api/historical-site/v1'
import { location } from '@project/api/location/v1'
import { home } from '@project/api/home/v1'
import { newsletter, newsletters } from '@project/api/newsletter/v1'
import { article, articles } from '@project/api/article/v1'

export default {
  async museumPage(slug) {
    return await museumPage(slug)
  },
  async historicalSite(slug) {
    return await historicalSite(slug)
  },
  async article(slug) {
    return await article(slug)
  },
  async articles(slug) {
    return await articles()
  },
  async location() {
    return await location()
  },
  async home() {
    return home()
  },
  async newsletter(slug) {
    const eh = await newsletter(slug)
    return eh
  },
  async newsletters() {
    return await newsletters()
  }
}

