//Cancel out the annoying formats for the phone-number
//https://stackoverflow.com/questions/3736807/how-do-i-remove-the-blue-styling-of-telephone-numbers-on-iphone-ios
export const meta = (meta) => {
  meta.addMetaItem([
    //<meta name="format-detection" content="telephone=no">
    //{ name:'format-detection', content: 'telephone=no' },
  ])
}

export const extraJS = [
  { src:"/vendor/jquery_jquery.min.js" },
  { src:"/vendor/vchistoryorg_external.js" },
  { src:"/vendor/vchistoryorg_custom.js" }
]

export const externalCSS = [
  {type:'webfont', href:'https://fonts.googleapis.com/css?family=Roboto%3A500%2C400%2C300%2C700&ver=6.3.1' },
  {type:'webfont', href:'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700|Google+Sans+Text:400&lang=en' },
  {type:'webfont', href:'https://fonts.googleapis.com/css?family=Google+Sans+Text:400&text=%E2%86%90%E2%86%92%E2%86%91%E2%86%93&lang=en' }
]

