<script>
import { defineComponent, computed, ref, inject } from "vue"
//import NavMainSearch from "@kit/components/NavMainSearch.vue"
//import { searchWidgetMain } from "@project/themes"
//import Column from '@kit/components/Column.vue'
//import NavBarBase from "@kit/components/NavBarBase.vue"
//import { pathname } from '@kit/utils/Formats'
///import IconButton, { ButtonIconActiveHeight } from "@kit/components/IconButton.vue"
//import { useStore } from "vuex"
//import { useRoute } from 'vue-router'


export default defineComponent({
  name: "NavBar",
  setup() {

    return { }

    // return { 
    //   searchWidgetMain, navBarBase, 
    //   navbarTheme, userAccount, atTop, 
    //   navMainSearch, 
    //   inMobile, navSubOptions, 
    //   nonDropDownNav, dropDownNav,
    //   drawerOut
    // };
  },
  components: {
    //NavMainSearch,
    //NavBarBase,
    //IconButton
  },
});
</script>

<style>

</style>

<template>
  <div>NAV</div>
  <!-- <NavBarBase ref="navBarBase" :theme="navbarTheme">
    
    <NavMainSearch ref="navMainSearch" :theme="searchWidgetMain" />
        
  </NavBarBase> -->

</template>
