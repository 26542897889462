import { get } from '@kit/utils/APIV1.js'

export const article = async(whichOne) => {
  return get(`article/slug/${whichOne}`)
}

export const articleSlugs = async() => {
  return get("article/slugs")
}

export const articles = async() => {
  return get(`articles`)
}