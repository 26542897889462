import { ButtonAlignCenter, ButtonFlashRadial, ButtonIconPositionShiftLeft, ButtonIconActiveHeight, ButtonIconPassiveHeight } from '@kit/components/IconButton.vue'


// --green: #acdd8c;
// --dark-green: #456745;

  export const greenButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: {   
      active:       "#3b634d",
      inactive:     "#CCCCCC",
      flash:        "#67a867", 
      loading:      "#CCCCCC",
      hover:        "#558255",  
      borderRadius: "5px",   
      padding:      "15px"     
    },
    iconPosition: ButtonIconPositionShiftLeft,
    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }

  export const blueButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: {   
      active:       "#5469d4",
      inactive:     "#31507a",
      flash:        "#4280d6", 
      loading:      "#31507a",
      hover:        "#4a61d4",  
      borderRadius: "3px",   
      padding:      "13px"     
    },
    iconPosition: ButtonIconPositionShiftLeft,
    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }


  export const footerButton = {...greenButton,
    background: {
      active:       "#405e3c",  
      inactive:     "#CCCCCC",
      flash:        "#6ab04a", 
      loading:      "#CCCCCC",
      hover:        "#2b4727",  
      borderRadius: "10px",   
      padding:      "10px"     
    }, 
    iconFromProject:true, 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    }
  }


  export const navMainSearchButton = { ...greenButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb sb-explicit sths-nav-main-search-iconbutton",
    background: {  
      none: true,
      padding:      "10px", 
      borderRadius: "0px"    
    },
    foreground: "#555555", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '30px'
    },
    //foreground: "#acdd8c",
    // iconDimension: {
    //   style: ButtonIconActiveHeight,
    //   active: '30px'
    // },
  }

  export const navSearchWidgetButton = { 
    ...greenButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb-explicit sths-nav-main-search-widget-button",
    foreground: "#FFFFFF",
    background: {  
      ...greenButton.background,
      borderRadius: "4px",  
      padding:      "5px",   
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '20px'
    },
  }

  export const prevNextSearchResultsButton = { 
    ...greenButton,
    background: { 
      ...greenButton.background, 
      padding:"7px 20px 7px 20px",
      borderRadius:"3px"
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '25px'  
    } 
  }
  


  export const roundCloseButton = {
    ...greenButton, 
    icon:"rounded-close-x_50", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    },
    background: {
      ...greenButton.background,
      borderRadius: "50%"   
    },
  }




  export const socialMediaButton = {
    foreground: "#163E6F",
    background: {
      active:       "#849CB3",
      inactive:     "#CCCCCC",
      flash:        "#42b6cf", 
      loading:      "#CCCCCC",
      hover:        "#388fa1",
      borderRadius: "50%",   
      padding:      "3px"     
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '50px'
    },
    iconFromProject: true,
    flash: ButtonFlashRadial,
    allowPropagate: false,
  }