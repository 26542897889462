
import { pathname } from '@kit/utils/Formats'
//import "@images/DLM-logo-5_Color-Small.png" 

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  outerContainerClassesDesktop:"ics-nav-outer ics-navbar-boxshadow",
  outerContainerClassesMobile:"ics-nav-outer ics-navbar-boxshadow",

  shrinkColor:"rgba(255,255,255,1)",
  backgroundColor:"rgba(255,255,255,1)",
  initialColor:"rgba(255,255,255,1)",

  hamburgerButtonClasses:"ics-nav-hamburger-nav-button--attop",

  //the logo and aria text
  logoWrapperClasses:"ics-logo-wrapper",
  //logoSrc:pathname("@images/DLM-logo-5_Color-Small.png" ),
  logoAriaAlt:"David L Moonie navigation logo",

  //required
  height: "90px",
  shrinkHeight: "50px",

  padding: "8px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "0px"
    
}

export const sideDrawer = {
  topNavHeight:"90px",
  outerClasses:"ics-side-nav-drawer"
}