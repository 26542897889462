<script>
import { defineComponent, watch, onMounted, onUnmounted, ref, inject } from 'vue'
// import "@kit/assets/css/superbox-core-x.css"
// import "@kit/assets/css/basic.css"
import "@project/assets/css/index.css"

import NavBar from '@components/NavBar.vue'
import Footer from '@components/Footer.vue'
import SideNavDrawer from '@components/SideNavDrawer.vue'
import { inBrowser, SSR, getEnv, setMobileThresholdFromApp, incrementResize } from '@kit/utils/EnvironmentHelper'
import { useStore } from 'vuex'
import { addEvent } from '@kit/utils/EventBus'
import { pathname } from '@kit/utils/Formats'
import { meta as modMeta, externalCSS as _modXCSS, extraJS as _modXJS} from '@project/modify/hydrate'
import Parallax from '@kit/utils/Parallax'
import '@images/favicon.png'

const wCtx = typeof window != 'undefined' ? window : null

export default defineComponent({
  name: "App",
  setup() {

    let dCache = null
    let dInterval = null
    const store = useStore()
    const hydrate = inject("hydrate")

    const mediaQuery = wCtx ? wCtx.matchMedia('(max-width : 960px)') : null
    
    const atThreshold = ref(false)
    const modXCSS = _modXCSS || []
    const modXJS = _modXJS || []

    //configure the parallax
    Parallax.config({
      debug: {
        showAreas:false
      },
      height: {
        query:"main",
        poll: {
          behavior: {
            stableThreshold: 1
          }
        }
      }
    })

    const debounced = (value) => {
      atThreshold.value = value
    }

    const debouncer = (value) => {
      if(!dInterval) {
        debounced(value)

        dInterval = setInterval(() => {
          if(dCache !== null) {
            debounced(dCache)
            dCache = null
          } else {
            clearInterval(dInterval)
            dInterval = null
          }
        },500)
        
      } else {
        dCache = value
      }
    }

    const screenResized = () => {
      incrementResize()
      const atThreshold = mediaQuery.matches
      setMobileThresholdFromApp(atThreshold)
      debouncer(mediaQuery.matches)
    }

    onMounted(async() => {
      if(wCtx) {

        addEvent("navigateToNewPage", ( info ) => {
          if(info.resetScrollPosition) {
            wCtx.scrollTo(0,0)
          }
        })

        wCtx.addEventListener('resize', screenResized, { passive:true })

        screenResized()

      }
    })
    onUnmounted(() => {
      if(inBrowser) {
        wCtx.removeEventListener('resize', screenResized, { passive:true })
      }
    })

    hydrate({
      metadata(meta) {
        meta.addMetaItem([
          { charset:'utf-8' },
          { name:'viewport', content: 'width=device-width, initial-scale=1.0' },
          { "http-equiv":'X-UA-Compatible', content: 'IE=edge'},
          { property:"og:type", content:"website" },
          { name:"google-site-verification", content:getEnv('GOOGLE_CSE_SITE_VERIFICATION')}
        ])
        meta.addHTMLAttribute({ name: "lang", value:"en" })
        meta.addAppAttribute([
          { name:"class", value:"sb sb-v"},
          { name:"style", value:"height:100%"}
        ])
        meta.addBodyAttribute(([
          { name:"class", value:"home page-template-default page page-id-2"}
        ]))
        meta.addFavIcon(pathname('@images/favicon.png'))
        modMeta(meta)
      },
      extraJS: [{ proxy:"google-cse", src:`google_cse_id=${getEnv("GOOGLE_CSE_ID")}`, async:"async" }, ...modXJS],
      externalCSS: modXCSS
    })


    watch(atThreshold, (newVal, oldVal) => {
      if(newVal && !oldVal) {
        store.dispatch("screenThreshold", newVal)
      } else 
      if(!newVal && oldVal) {
        store.dispatch("screenThreshold", newVal)
      }
    }, {immediate:true})

    return { inBrowser, SSR }

  },

  components: {
    // NavBar,
    // PageFooter: Footer,
    // SideNavDrawer
  },
});
</script>

<style scoped>
  .do-not-show {
    position:fixed; 
    width:0px; 
    height:0px; 
    display:none; 
    overflow:hidden;
  }
</style>

<template>

    <div id="header-image"></div>
    <div id="page" class="hfeed site">
      <div id="top-section">
        <div id="mobile-nav">
          <a id="#menu" class="menu-link">&#9776;</a>
          <nav id="menu" class="panel" role="navigation">
            <div class="menu-menu-1-container">
              <ul id="menu-menu-1" class="menu">
                <li id="menu-item-66" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-66">
                  <router-link to="/">Home</router-link>
                </li>
                <li id="menu-item-66" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-66">
                  <router-link to="/exhibits">Exhibits</router-link>
                </li>
                <li id="menu-item-304" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-304">
                  <router-link to="/historical-sites">Historical Sites</router-link>
                </li>
                <li id="menu-item-308" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-308">
                  <router-link to="/articles">Articles</router-link>
                </li>
                <li id="menu-item-305" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-305">
                  <router-link to="/donate">Donate</router-link>
                </li>
                <li id="menu-item-307" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-307">
                  <router-link to="/membership">Membership</router-link>
                </li>
              </ul>
            </div>
          </nav>
          <!-- #site-navigation -->
        </div>
        <header id="masthead" class="site-header" role="banner">
          <div class="site-branding">
            <h1 class="site-title">
              <router-link to="/" rel="home">Valley Center History Museum</router-link>
            </h1>
            <h2 class="site-description">Valley Center Historical Society</h2>
          </div>
          <div id="social-icons">
            <div class="container"></div>
          </div>
        </header>
        <!-- #masthead -->
        <div id="slickmenu"></div>
        <div class="nav-container container">
          <nav id="site-navigation" class="main-navigation" role="navigation">
            <div class="menu-menu-1-container">
              <ul id="menu-menu-2" class="menu">
                <li id="menu-item-66" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/">
                    Home
                  </router-link>
                </li>
                <li id="menu-item-66" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/exhibits">
                    Exhibits 
                  </router-link>
                </li>
                <li id="menu-item-304" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/historical-sites">
                    Historical Sites
                  </router-link>
                </li>
                <li id="menu-item-308" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/articles">
                    Articles
                  </router-link>
                </li>
                <li id="menu-item-305" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/donate">
                    Donate
                  </router-link>
                </li>
                <li id="menu-item-307" class="menu-item menu-item-type-post_type menu-item-object-page">
                  <router-link to="/membership">
                    Membership 
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
          <!-- #site-navigation -->
        </div>
      </div>
      <!--#top-section-->
      <div id="content" class="site-content container">


        <!-- This will be the route view -->
        <router-view></router-view>
        <!-- end of the route-view -->
        <!-- #primary -->
        <div id="secondary" class="widget-area" role="complementary">
          <aside id="nav_menu-2" class="widget widget_nav_menu">
            <h3 class="widget-title">Museum &#038; Historical Society</h3>
            <div class="menu-menu-2-container">
              <ul id="menu-menu-3" class="menu">
                <li id="menu-item-111" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-111">
                  <router-link to="/about-us">About Us</router-link>
                  <ul class="sub-menu">
                    <li id="menu-item-117" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-117">
                      <router-link to="/contact">Contact</router-link>
                    </li>
                    <li id="menu-item-117" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-117">
                      <router-link to="/newsletters">Newsletters</router-link>
                    </li>
                    <li id="menu-item-144" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-144">
                      <router-link to="/location">Location</router-link>
                    </li>
                    <li id="menu-item-923" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-923">
                      <router-link to="/museum-store">Museum Store</router-link>
                    </li>
                  </ul>
                </li>
                <li id="menu-item-110" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-110">
                  <router-link to="/exhibits">Exhibits</router-link>
                </li>
                <li id="menu-item-110" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-110">
                  <router-link to="/historical-sites">Historical Sites</router-link>
                </li>
                <li id="menu-item-110" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-110">
                  <router-link to="/articles">Articles</router-link>
                </li>
                <li id="menu-item-110" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-110">
                  <router-link to="/board-members">Board Meetings &#038; Members</router-link>
                </li>
                <li id="menu-item-224" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-224">
                  <router-link to="/history-book-inventory">History Book Inventory</router-link>
                </li>
                <li id="menu-item-651" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-651">
                  <router-link to="/donate">Donate</router-link>
                </li>
                <li id="menu-item-652" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-652">
                  <router-link to="/membership">Membership</router-link>
                </li>
              </ul>
            </div>
          </aside>
          <aside id="nav_menu-3" class="widget widget_nav_menu">
            <h3 class="widget-title">Local History</h3>
            <div class="menu-history-container">
              <ul id="menu-history" class="menu">
                <li id="menu-item-119" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-119">
                  <router-link to="/history-of-valley-center">History of Valley Center</router-link>
                </li>
                <li id="menu-item-118" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-118">
                  <router-link to="/history-of-pauma-valley">History of Pauma Valley</router-link>
                </li>
                <li id="menu-item-818" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-818">
                  <router-link to="/local-native-americans">Local Native Americans</router-link>
                </li>
                <li id="menu-item-359" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-359">
                  <router-link to="/valley-center-western-days">Valley Center Western Days</router-link>
                </li>
                <li id="menu-item-571" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-571">
                  <router-link to="/vc-community-plan">Community Plan</router-link>
                </li>
              </ul>
            </div>
          </aside>
          <aside id="nav_menu-4" class="widget widget_nav_menu">
            <h3 class="widget-title">External VC Links</h3>
            <div class="menu-links-container">
              <ul id="menu-links" class="menu">
                <li id="menu-item-866" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-866">
                  <router-link to="/visit-valley-center">Visit Valley Center</router-link>
                </li>
                <li id="menu-item-743" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-743">
                  <a href="http://www.valleycenter.com/">VC Newspaper / Local Media</a>
                </li>
                <li id="menu-item-151" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-151">
                  <a href="http://www.findagrave.com/cgi-bin/fg.cgi?page=cr&amp;CRid=8422">VC Cemetery Gravesites</a>
                </li>
              </ul>
            </div>
          </aside>
          <aside id="text-2" class="widget widget_text">
            <h3 class="widget-title">Media Historical Archive</h3>
            <div class="textwidget">The Valley Roadrunner (Valley Center's Newspaper) archive is maintained by the Valley Center Historical Society and is available for public reference</div>
          </aside>
        </div>
        <!-- #secondary -->
        <div id="footer-sidebar" class="widget-area clear" role="complementary">
          <div class="footer-column"></div>
        </div>
        <!-- #secondary -->
      </div>
      <!-- #content -->
      <footer id="colophon" class="site-footer" role="contentinfo">
        <div class="container">
          <div class="site-info"> Sixteen Theme by <a target="blank" href="http://inkhive.com/" rel="designer">InkHive</a>
          </div>
          <!-- .site-info -->
          <div id="footertext"></div>
        </div>
        <!--.container-->
      </footer>
      <!-- #colophon -->
    </div>
    <!-- #page -->
    <div style="width:100%;text-align:center; font-size:11px; clear:both">
      <a target="_blank" title="Insert HTML Snippet Wordpress Plugin" href="http://xyzscripts.com/wordpress-plugins/insert-html-snippet/">HTML Snippets</a> Powered By : <a target="_blank" title="PHP Scripts & Wordpress Plugins" href="http://www.xyzscripts.com/">XYZScripts.com</a>
    </div>


  <div class="do-not-show">
    <div class="gcse-searchbox" data-autoCompleteMaxCompletions="5"></div>
  </div>
  <div class="do-not-show">
    <div class="gcse-searchresults"></div>
  </div>
</template>

