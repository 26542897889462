import { historicalSiteSlugs } from '@project/api/historical-site/v1'
import { museumPageSlugs } from '@project/api/museum-page/v1'
import { newsletterSlugs } from '@project/api/newsletter/v1'
import { articleSlugs } from '@project/api/article/v1'

export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
  },
  {
    name: 'museum-page',
    path: '/:mslug',
    component: () => import(/* webpackChunkName: "museum-page" */'@pages/MuseumPage.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await museumPageSlugs()
        }
      }
    }
  },
  {
    name: 'newsletters',
    path: '/newsletters',
    component: () => import(/* webpackChunkName: "newsletters" */'@pages/Newsletters.vue'),
  },
  {
    name: 'newsletter',
    path: '/newsletter/:nslug',
    component: () => import(/* webpackChunkName: "newsletter" */'@pages/Newsletter.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await newsletterSlugs()
        }
      }
    }
  },
  {
    name: 'location',
    path: '/location',
    component: () => import(/* webpackChunkName: "location" */'@pages/Location.vue'),
  },
  {
    name: 'donate-online',
    path: '/donate/donate-online',
    component: () => import(/* webpackChunkName: "donate" */'@pages/DonateOnline.vue'),
  },
  {
    name: 'donate-thanks',
    path: '/donate/thanks',
    component: () => import(/* webpackChunkName: "donate" */'@pages/Thanks.vue'),
  },
  {
    name: 'historical-site-page',
    path: '/historical-sites/:hslug',
    component: () => import(/* webpackChunkName: "historical-sites" */'@pages/HistoricalSitePage.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await historicalSiteSlugs()
        }
      }
    }
  },

  {
    name: 'articles',
    path: '/articles',
    component: () => import(/* webpackChunkName: "articles" */'@pages/Articles.vue'),
  },

  {
    name: 'article',
    path: '/article/:aslug',
    component: () => import(/* webpackChunkName: "article-page" */'@pages/ArticlePage.vue'),
    meta: {
      sitemap: {
        slugs: async() => {
          return await articleSlugs()
        }
      }
    }
  },

]