import VueSmoothScroll from 'vue3-smooth-scroll'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { getEnv } from '@kit/utils/EnvironmentHelper'
import VCHMApp from '@project/components/VCHMApp.vue'


export const modifyProvideApp = () => {
  return VCHMApp
}

export const modifyApp = (app, router) => {

  app.use(VueGoogleMaps, {
    load: {
      key: getEnv('GOOGLE_MAPS_NORMAL_API_KEY')
    }  
  })

  app.use(VueSmoothScroll, {
    updateHistory: false
  })

}
