import { get } from '@kit/utils/APIV1.js'

export const museumPage = async(whichOne) => {
  return get(`museum-page/slug/${whichOne}`)
}

export const museumPageSlugs = async() => {
  return get("museum-page/slugs")
}

