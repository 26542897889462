import { ButtonIconActiveHeight } from '@kit/components/IconButton.vue'
import { ModalPositionCenter } from '@kit/components/Modal.vue'
import { greenButton } from '@project/theme-buttons'

export const paymentModal = {
  windowAutoPosition: true,
  position: ModalPositionCenter,
  backgroundClasses: "dlm-modal-background",
  windowClasses: "dlm-modal-window",
  closeButton: {
    ...greenButton, 
    icon:"rounded-close-x_50", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    },
    background: {
      ...greenButton.background,
      borderRadius: "50%"   
    },
  }
}