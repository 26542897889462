import { get } from '@kit/utils/APIV1.js'

export const newsletters = async() => {
  return get(`newsletters`)
}
export const newsletter = async(whichOne) => {
  return get(`newsletter/slug/${whichOne}`)
}
export const newsletterSlugs = async() => {
  return get(`newsletter/slugs`)
}

