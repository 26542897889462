
<script>
import { defineComponent, computed, onMounted } from 'vue'
import { footerButton } from '@project/themes'
import IconButton from '@kit/components/IconButton.vue'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { getCurrentYear } from '@kit/utils/Formats'
import ParallaxImage from '@kit/components/ParallaxImage.vue'
import Column from '@kit/components/Column.vue'

export default defineComponent({
  name: "Footer",
  setup() {
    //const store = useStore()
    //const locations = computed(() => store.state.locations)
    const locations = computed(() => [])

    onMounted(() => {})

    return { 
      locations, 
      footerButton, 
      inBrowser,
      getCurrentYear
    }

  },
  components: {
    IconButton,
    ParallaxImage,
    Column
  }
});
</script>


<template>
  <h1>Im the footer</h1>
</template>