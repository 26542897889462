// export const lazyImgTheme1 = {
//   loader: {
//     wheel: "Rolling",
//     fromProject: true,
//   },
//   background: true,
//   style:"background-size:cover; background-repeat:no-repeat; background-position:center; width:100%; height:auto; padding: 30px;",
//   loadingStyle:"background-size:contain; background-repeat:no-repeat; background-position:center; background-size:40%; width:100%; height:100%;"
// }
  

export const lazyImgTheme1 = {
  loader: {
    wheel: "Rolling",
    fromProject: true,
  },
  background: false,
  style: "object-fit:cover; width:100%; height:100%;",
  loadingStyle:"background-size:contain; background-repeat:no-repeat; background-position:center; background-size:20%; width:100%; height:100%;"
}

// export const lazyImgTheme2 = {
//   loader: {
//     wheel: "Rolling",
//     fromProject: true,
//   },
//   background: true,
//   style:"background-size:cover; background-repeat:no-repeat; background-position:center; width:100%; height:auto; padding: 30px;",
//   loadingStyle:"background-size:contain; background-repeat:no-repeat; background-position:center; background-size:40%; width:100%; height:100%;"
// }
  
  // export const lazyImgTheme2 = {
  //   loader: {
  //     wheel: "Spinner-04s-200px",
  //     fromProject: false,
  //   },
  //   loadingStyle: "object-fit:contain; width:100%; height:auto; padding: 30px;",
  // }
  
  // export const lazyImgForFancyContentAreas = {
  //   loader: {
  //     wheel: "default",
  //     fromProject: true,
  //   },
  //   background:true,
  //   style:"background-size:cover; background-repeat:no-repeat; background-position:center; width:100%; height:auto; padding: 30px;",
  //   loadingStyle:"background-size:contain; background-repeat:no-repeat; background-position:center; background-size:40%; width:100%; height:100%;"
  // }
  